import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Box, Heading, VStack } from "@chakra-ui/react";
import Container from "src/components/Container";
import ContactBanner from "src/components/banners/ContactBanner";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/contact/" } }) {
      frontmatter {
        banner_text
        before_form_grid
      }
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeadSEO
        title="Contact | Evandale Village Fair"
        description="Contact the Evandale Village Fair and National Penny Farthing Championships."
        slug="/contact/"
      />
      <ContactBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="65% 30%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Heading as="h1" size="2xl">
              {page.banner_text}
            </Heading>
          </VStack>
        </Container>
      </ContactBanner>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.before_form_grid} />
          </VStack>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="formGrid">
              <div className="field half">
                <label htmlFor="name half">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className="field full">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" rows="6" />
              </div>
              <div className="field quarter">
                <input type="submit" value="Send Message" className="special" />
              </div>
            </div>
          </form>
        </Container>
      </Box>
    </Layout>
  );
};

export default Contact;
